<template>
  <div class="googleMapsContainer">

      <gmap-map
        :center="mapCenter"
        :zoom="mapZoom"
        :options="mapOptions"
        style="width: 100%; height:80vh;"
        ref="searchMap"
        @bounds_changed="runAlt"
      >
      <gmap-marker
        :position="center"
        :icon="mapsIcon"
        :clickable="true"
        :draggable="false"
        @click="centerAndZoom"
      ></gmap-marker>
      <!-- <GmapCluster :styles="clusterStyles" @click="centerAndZoom"> -->
        <gmap-marker
          :key="index"
          v-for="(room, index) in currentRooms"
          :position="room.general.location"
          :clickable="true"
          :draggable="false"
          @click="centerAndZoom"
        ></gmap-marker>
      <!-- </GmapCluster> -->
      </gmap-map>
    </div>
  </template>

<script>
import Vue from 'vue'
// import GmapCluster from 'vue2-google-maps/src/components/cluster'

// Vue.component('GmapCluster', GmapCluster)

export default {
  props: ['rooms','center','zoom'],
  data: () => ({
    //CHANGE THE CLUSTER URL FOR THE different icon
    cloned:false,
    mapOptions:{
      maxZoom: 17,
    },
    latBounds:[],
    lngBounds:[],
    mapsIcon:'https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_40/v1537938360/clusterIcons/mapsIcon.png',
    clusterStyles: [{
          textColor: 'white',
          url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1537938359/clusterIcons/m1.png',
          height: 55,
          width: 55
        },
        {
          textColor: 'white',
          url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1537938359/clusterIcons/m2.png',
          height: 56,
          width: 55
        },
        {
          textColor: 'white',
          url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1537938359/clusterIcons/m3.png',
          height: 66,
          width: 65
        },
        {
          textColor: 'white',
          url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1537938360/clusterIcons/m4.png',
          height: 78,
          width: 77
        },
        {
          textColor: 'white',
          url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1537938360/clusterIcons/m5.png',
          height: 90,
          width: 89
        }
      ]
    }),

    methods: {
      runAlt(){
        // console.log('BOUNDS CHANGED SO RUN RETURNMARKERS AGAIN:')
        this.returnMarkers()
      },
      centerAndZoom(payload){
        // console.log('zooming here:')
        this.mapCenter.lat=payload.center_.lat()
        this.mapCenter.lng=payload.center_.lng()
      },
      returnMarkers() {
        let newRooms=[]
        if(this.rooms!=undefined && this.$refs.searchMap.$mapObject!=undefined && this.$refs.searchMap.$mapObject.getBounds()!=undefined && this.$refs.searchMap.$mapObject.getBounds()!=null) {
          for (var i = 0; i < this.rooms.length; i++) {
            // console.log(this.rooms[i]);
            if(this.rooms[i].general.location!=undefined && this.$refs.searchMap.$mapObject.getBounds().contains(this.rooms[i].general.location) ){
              newRooms.push(this.rooms[i])
            }
          }
        } else {
          // console.log('thers a problem with the rooms and apts');
        }
        this.$emit('changeVisibleRooms',newRooms)
      }
    },
    watch:{
      rooms: function() {

        // console.log('newRooms: ', newRooms);
          this.returnMarkers()
      },
    },
    computed:{
      mapCenter(){
        return this.center
      },
      mapZoom(){
        return parseInt(this.zoom)
      },
      currentRooms(){
        // console.log('Set Rooms',this.rooms.length);
        return this.rooms
      }

    },
    created() {
      // console.log('create map,', this.$refs, 'searchmap:',this.$refs.searchMap);
      //do something after creating vue instance
    },
    components:{
      // GmapCluster
    }
  }
</script>
