<template>
<div>
  <navbar></navbar>
  <SearchBarRooms view="apts":filters="filters" :min="min" :max="max"></SearchBarRooms>
  <v-layout  wrap fluid mt-1>
    <v-flex xs12 sm8 class="">
      <Loading v-if="loading==true"></Loading>
      <v-layout  wrap v-else class="results-col">
        <v-flex xs12 class="px-3">
          <div class="" v-if="urgencyVal==3">
            <v-layout  align-center>
              <v-flex shrink>
                <v-icon color="primary" class="pr-3">info</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold">15% of users arriving in {{filters.mid | month}} have already booked their rooms.</span>
                <br>We recommend to your book at least 6 months in advance
              </v-flex>
            </v-layout>
          </div>
          <div class="" v-if="urgencyVal==2" class="">
            <v-layout  align-center>
              <v-flex shrink>
                <v-icon color="#ff4000" class="pr-3" large>access_alarm</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold">More than 35% of users arriving in {{filters.mid | month}} have already booked their rooms. </span>
                <br> We recommend booking your place as soon as possible.
              </v-flex>
            </v-layout>
          </div>
          <div class="" v-if="urgencyVal==1">
            <v-layout  align-center>
              <v-flex shrink>
                <v-icon color="red" v-if="percAv<30" large class="pr-3">warning</v-icon>
                <v-icon color="#ff4000" v-else-if="percAv<65" large class="pr-3">access_alarm</v-icon>
                <v-icon color="primary" v-else="" class="pr-3">info</v-icon>
              </v-flex>
              <v-flex>
                <span class="font-weight-bold" >Only {{percAv}}% of apartments are still available for these dates.</span>
                <br>We recommend booking your apartment soon.
              </v-flex>
            </v-layout>
          </div>
        </v-flex><v-flex xs12 sm6 md4 class="pa-2" v-for="apt in displayed" v-if="apt.hasOwnProperty('general')" :key="apt['.key']" @mouseleave="hoverRoom=null"  @mouseover="hover(apt)" >
          <AptCard :mid="filters.mid" :fx="fx" :currency="currency" :lat="center.lat" :lng="center.lng" :mod="filters.mod" :apt="apt" @setDistance="setDistance(apt, $event)" ></AptCard>
        </v-flex>
        <v-flex xs12 class="no-results" v-if="this.displayed.length==0">
          <span class="no-results-text" v-if="loading==false">{{$t('resultsPage.noData1')}} <br>{{$t('resultsPage.noData2')}}</span>
          <!-- <v-btn color="primary" @click="resetFilters">{{$t('resultsPage.removeFilters')}}</v-btn> -->
        </v-flex>
        <v-flex xs12 class="pa-4">
          <v-pagination :length="numberOfPages" v-model="page" :total-visible="6" circle></v-pagination>
        </v-flex>

      </v-layout>
    </v-flex>
    <v-flex xs12 sm4>
      <!-- <SearchMap :center="center" :rooms="filtered" :zoom="zoom" @changeVisibleRooms="setDisplayed">
      </SearchMap> -->
      <OpenMaps style="z-index=1!important;" :hoverRoom="hoverRoom" :ordered="ordered" :page="page" :center="center" :rooms="filtered" :place="place" :zoom="zoom" @changeVisibleRooms="setDisplayed" @resetPage="page=1"></OpenMaps>

    </v-flex>
  </v-layout>
  <v-layout justify-center ma-2 wrap>
    <v-flex xs11 sm7 class="box">
      <Area :noButton="true" :area="area"></Area>
    </v-flex>
  </v-layout>
</div>
</template>

<script>
import Loading from '../shared/Loading.vue'
import firebase from '../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import SearchBarRooms from './SearchBarRooms.vue'
import AptCard from './AptCard.vue'
import SearchMap from './SearchMap.vue'
import OpenMaps from './OpenMaps.vue'
import inside from 'point-in-polygon'
import Area from '../Area.vue'

export default {
  name: 'Search',
  data: () => ({
    center: {
      lat: 25.0247833,
      lng: 121.5359923
    },
    zoom:13,
    min:8000,
    max:18000,
    allRooms:[],
    filtered:[],
    displayed:[],
    allApts:[],
    roomsLoaded:false,
    studiosLoaded:false,
    apartmentsLoaded:false,
    numberAvNow:0,
    wholeAptsLoaded:false,
    filters:{
      locName:'',
      mid:"",
      mod:"",
      propType:"",
      bedType:"",
      aptType:"",
      nor:"",
      price:"",
      orderBy:"",
      cLength:"",
    },
    fx:1,
    ordered:false,
    place:{
      url:"https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,w_150/v1567997139/-LOq9P_99hK-THgGA5zN/oytdxvp9awuh6uxcayq7.jpg",
      title:"Taipei",
      info:"Welcome to Taipei"
    },
    ordered:false,
    numberOfPages:1,
    page:1,
    others:[],
    hoverRoom:null,
    area:"daanStudent",
    areas:{
      yonghe:{
        title:"Yonghe",
        center:{
          lat:25.011324,
          lng:121.515887,
        },
        dyn:2,
        price:1,
        comm:3,
        mrt:"O Dingxi, O Yongan Market,",
        desc:"Yonghe is a New Taipei City District. The area is local and authentic residential area. Yonghe district is a great and cheap alternative for students who are studying around Shida. The prices are much lower than in the center. And you can find great cheap rooms (with international landlords) just a few MRT stops from NTU. There are big supermarkets, lots of convenience store (like everywhere), all sorts of local shops and plenty of small local food restaurants and night markets. However, as Wenshan, it is quieter area with many Taiwanese residents, who expect you to be calm and respectful. In this area, people are less use to International students and to speaking English. Moreover, the area is less dynamic than Daan or Xinyi.",
      },
      wenshan:{
        title:"Wenshan",
        center:{
          lat:24.998776,
          lng:121.576997,
        },
        dyn:1,
        price:1,
        comm:1,
        mrt:"BR Xinhai, BR Wanfang Hospital, BR Wanfang Community, BR Muzha, BR Taipei Zoo, GR Wanlong, GR Jingmei",
        desc:"The district is surrounded by Mountains on three sides. \nThe area hosts NCCU one of the biggest university in Taipei. \nThe area is way cheaper than Shida! It is a local residential and quiet neighbourhood. You will find small local restaurants and all sorts of shops, but unlike more dynamic areas everything will close earlier (around 8-9pm rather than 10-11pm) and other local resident will expect you to be calm. Note that there are many international students studying at NCCU. The University provides dorms, called the High House, to its students. \nIt is relatively cheap (for shared rooms) but as any other Taiwanese dorms, it has strict rules regarding friends coming over and parties. It is the perfect area for nature lovers who want to live the authentic local experience. If you live around NCCU, it is further from the other international students (all located around Shida), and from the nightlife area. However, the NCCU exchange student community is substantial and pretty active. \n This is the perfect place for nature lovers who want to live the authentic local experience!",
      },
      xinyi:{
        title:"Xinyi",
        center:{
          lat:25.032828,
          lng:121.564853,
        },
        dyn:4,
        price:4,
        comm:2,
        mrt:"BL Taipei City Hall, BL Yongchun, R Taipei 101/ World Trade Center, R Xiangshan",
        desc:"Xinyi district is the heart of the city where you can find its highest tower, Taipei 101. The area is more a business, offices and meeting center area. It is also full of famous high-end restaurants, wonderful shopping malls and all the luxury hotels. It is also where Taipei’s night life is at the highest. All the trendy nightclubs and bars are in Xinyi. But, as you can expect, this district is expensive. If you want to live there, your rent will be ridiculously high!\nIn other word,Xinyi is the trendy, high -end, expensive and modern area. Some describe Xinyi as the Times Square of Taipei. If you stay in Taipei, you will definitely step foot in this area.",
      },
      daanStudent:{
        title:"Daan",
        center:{
          lat:25.021325,
          lng:121.541751,
        },
        dyn:3,
        price:2,
        comm:4,
        mrt:"GR Taipower Building, GR Gongguan, GR Guting, BR- R Daan, BR Luiziangli, BR Linguang, R Daan park, R Xinyi Anhe",
        desc:"In Shida area, you will find all main Taiwanese Universities. ( NTU, NTUST, NTNU, NTUE, NTUB) and all the language centers.\nAs an exchange student, language student or teacher, this place is an awesome place to live in. It is a dynamic and international neighborhood with plenty of small restaurants and bars as well as smaller boutiques and the famous Shida Night Market. It is also a great place for NCCU Students since you have direct connections to the University.",
      },
      zhongzheng:{
        title:"Zhongzheng",
        center:{
          lat:25.031550,
          lng:121.513101,
        },
        dyn:3,
        price:2,
        comm:4,
        mrt:"GR-R CKS Memorial Hall, GR-O Guting, GR Taipower Building, GR Gongguan",
        desc:"Zhongzheng under the green line is in my opinion a addendum to Shida Aera. As it is as cheap as Shida. It is around the same MRT stations and close to the universities. It is this international and dynamic area that makes it an awesome place to live.",
      },
      daanExpat:{
        title:"Daan for expats",
        center:{
          lat:25.036833,
          lng:121.538648,
        },
        dyn:3,
        price:3,
        comm:4,
        mrt:"BL Zhongxiao Xinsheng, BL-BR Zhongxiao Fuxing, BL Zhongxiao Dunhua, BL Sun Yat Sen Memorial Hall, BR- R Daan, R Daan park, R Xinyi Anhe",
        desc:"This residential area is really dynamic and international. It it the expats favourite neighbourhood. The area actually used to be the hub for international travelers before the rise of the Xinyi District. It is full of cool bars, small restaurants, and it is a paradize for shopaholics. On the main street of Zhongxiao East Road 忠孝東路, you’ll find global brands like Zara, Cartier, Chanel…etc. And if you wander through the small alleys you’ll find cool smaller boutiques. \nIn short, this part of Daan District is really nice place to live as young international worker, or as students. It is in between Shida, the student area and Xinyi, the trendy nightlife area. And you can expect the prices to be in between as well.",
      },
      zhongshan:{
        title:"Zhongshan",
        center:{
          lat:25.061709,
          lng:121.522975,
        },
        dyn:1,
        price:3,
        comm:2,
        mrt:"GR-R Zhongshan, GR-O, Songjiang Nanjing, GR-BR Nanjing Fuxing, Br Zhongshan Junior High School, O Xingtian Temple, O Zhongshan Elementary School, R-O Minquan W. Rd., R Shuanglian",
        desc:"There are a lot a recreational area in Zhongshan district.\nThis district is nice for a local experience. It is not really popular amongst international but well worth checking. It has a lot to offer and some neighbourhood host plenty of American and Japanse expats.",
      },
      songshan:{
        title:"Songshan",
        center:{
          lat:25.055213,
          lng:121.565167,
        },
        dyn:1,
        price:3,
        comm:2,
        mrt:"GR Songshan, GR Nanjing Sanmin, GR Taipei Area, GR-BR Nanjing Fuxing, BR Zhongshan Junior High School, BR MRT Taipei Songshan Airport Zhan",
        desc:"This district is the financial district. The area is really dynamic and full of suit and tie during the day. But after work hours this area becomes quite calm and restaurants close early. There is nevertheless a famous night market which makes the area pretty dynamic at night: Raohe Street 饒河街.",
      },
      neihu:{
        title:"Neihu",
        center:{
          lat:25.074771,
          lng:121.579457,
        },
        dyn:1,
        price:4,
        comm:4,
        mrt:"BR Dazhi, BR Neihu",
        desc:"Unlike the other out centered districts, Neihu is more developed and expensive. Dazhi and Neihu are the home of many big technology corporation that helped Taipei obtain the status of International Metropolis. Logically, these areas welcome more business travelers and long term international workers. The place is well developed and modern. It is a quiet area, surrounded by nature (riverside park, mountains,…). If you wander around the streets you will mainly see luxury apartments buildings, high end restaurants and nice malls with famous international brands and nice movie theatre with English movies.",
      },
      shilin:{
        title:"Shilin",
        center:{
          lat:25.096535,
          lng:121.524001,
        },
        dyn:2,
        price:2,
        comm:4,
        mrt:"R Jiantan, R Shilin, R Zhishan",
        desc:"Shilin offers outstanding living environment. This is European and American expats favorites. It is the perfect area if you are going to study at Sochow or at Chinese Culture University but pretty far from the Shida area. \nThis district is perfect for the nature lovers. It Is full of parks and it has Yangmingshan National Park. You also find the famous shilin night market and the National Palace Museum there.",
      },
      beitou:{
        title:"Beitou",
        center:{
          lat:25.135699,
          lng: 121.502039,
        },
        dyn:1,
        price:1,
        comm:3,
        mrt:"R Mingde. R Qilian, R Qiyan, R Beitou, R Fuxinggang, R Zhongyi, R Guandu",
        desc:"Beitou is a nice place, one of the nicest inTaipei. If you love nature, you will love Beitou. It also attracts a lot of tourist for its world renowned hot springs and the magnificent scenery it offers. Yangmingshan National park, Gandu, Xiaoyoukeng, Zhuzihu, Qingtiangang, Junjian Rock, and Guizkeng etc. However, it is quite far from the city. If you plan on working or studying in the city, I would advise not to live that far from it as you would spend to much time commuting.",
      },
      tamsui:{
        title:"Tamsui",
        center:{
          lat:25.174403,
          lng:121.473685,
        },
        dyn:2,
        price:1,
        comm:2,
        mrt:"R Zuhwei, R Hongshulin, R Tamsui",
        desc:"Located in the northern part of Taiwan, Tamsui is a New Taipei City District. It is next to the harbour. You can find great and cheap rooms in this area and the beaches are easily reachable by car or bus. It is however less convenient for Shida students who have to go to their university every day.",
      },

    },

  }),
  firebase() {
    return {
      rooms: {
        source:firebase.database().ref('rooms').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          this.roomsLoaded=true
          this.matchRoomsApts()
        }

      },
      apartments: {
        source:firebase.database().ref('apartments').orderByChild('draft').equalTo(false),
        asObject:true,
        readyCallback:function(){
          for (var item in this.apartments) {
            if (this.apartments.hasOwnProperty(item) && this.apartments[item].hasOwnProperty('general')) {
              this.apartments[item].url='/apartmentDetails/'+item+'/rooms'
              this.apartments[item].type='roomApt'
              this.apartments[item]['.key']=item
              this.apartments[item].billing={price:0}
              this.allApts.push(this.apartments[item])
            }
          }
          this.apartmentsLoaded=true
          this.matchRoomsApts()
        }
      },
      wholeApts: {
        source:firebase.database().ref('wholeApts').orderByChild('draft').equalTo(false),
        readyCallback:function(){
          for (var i = 0; i < this.wholeApts.length; i++) {
            this.wholeApts[i].type='wholeApt'
            this.wholeApts[i].url='/apartmentDetails/'+this.wholeApts[i]['.key']+'/apartment'
            this.checkMinMax(this.wholeApts[i])
            this.allRooms.push(this.wholeApts[i])
            this.allApts.push(this.wholeApts[i])
          }
          this.wholeAptsLoaded=true
        }
      },
    }
  },
  methods: {
    hover(room){
      this.hoverRoom=room;
    },
    setArea(location){

      console.log('SET AREA,');
      if (inside([location.lat,location.lng],[[25.009265, 121.493519],[25.022660, 121.513878],[25.007928, 121.532117],[24.995454, 121.530985]])){
        this.area="yonghe"
      } else if ( inside([location.lat,location.lng],[[25.022660, 121.513878],[25.007928, 121.532117],[25.011120, 121.537355],[25.034589, 121.516956],[25.037567, 121.507363],[25.030265, 121.504245]])){
        this.area='zhongzheng'
      } else if ( inside([location.lat,location.lng],[[25.011120, 121.537355],[25.034589, 121.516956],[25.033037, 121.553444],[25.011379, 121.563730]])){
        this.area="daanStudent"
      } else if ( inside([location.lat, location.lng],[[24.995454, 121.530985],[25.011120, 121.537355],[25.011379, 121.563730],[25.016621, 121.598156],[24.979995, 121.600270],[24.969820, 121.567915]])){
        this.area="wenshan"
      } else if ( inside([location.lat, location.lng],[[25.037619, 121.595279],[25.011379, 121.563730],[25.033037, 121.553444],[25.045204, 121.561669],[25.044696, 121.578818]])){
        this.area="xinyi"
      } else if ( inside([location.lat, location.lng],[[25.045043, 121.532872],[25.045204, 121.561669],[25.044696, 121.578818],[25.052327, 121.578807],[25.074372, 121.569722],[25.075832, 121.543495]])){
        this.area="songshan"
      } else if ( inside([location.lat, location.lng],[[25.045204, 121.561669],[25.033037, 121.553444],[25.034589, 121.516956],[25.045043, 121.532872]])){
        this.area="daanExpat"
      } else if ( inside([location.lat, location.lng],[[25.034589, 121.516956],[25.045043, 121.532872],[25.075832, 121.543495],[25.076026, 121.506507],[25.052189, 121.504726]])){
        this.area="zhongshan"
      } else if ( inside([location.lat, location.lng],[[25.075832, 121.543495],[25.074372, 121.569722],[25.065426, 121.616447],[25.101222, 121.611935],[25.091674, 121.542136]])){
        this.area="neihu"
      } else if ( inside([location.lat, location.lng],[[25.091674, 121.542136],[25.166770, 121.572119],[25.101222, 121.611935],[25.075832, 121.543495],[25.076026, 121.506507],[25.095602, 121.509428]])){
        this.area="shilin"
      } else if ( inside([location.lat, location.lng],[[25.095602, 121.509428],[25.166770, 121.572119],[25.209079, 121.561712],[25.125054, 121.458978]])){
        this.area="beitou"
      } else if ( inside([location.lat, location.lng],[[25.125054, 121.458978],[25.209079, 121.561712],[25.227988, 121.443948],[25.183033, 121.404265]])){
        this.area="tamsui"
      }
    },

    setDistance(apt, payload){
      apt.distance=payload
      let el = this.allApts.find(it=>{
        return it['.key']==apt['.key']
      })
      el.distance=payload
    },
    checkQuery(){
      // console.log('route: ', this.$route);
      if (this.$route.query!=undefined && this.$route.query.id!=undefined){
        console.log('post the query RTS:',this.$route.query.rts, "+ID: ",this.$route.query.id);
        axios.post('eOpens.json',{
          user:this.$route.query.id,
          rts: this.$route.query.rts,
        })
      } else {
        console.log("no query worth noting" ,this.$route.query);
      }
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      let expires = "expires=" + d.toUTCString()
      document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/"
    },
    getCookie(cname) {
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        // console.||('COOKIE NUMBER :' + i)
        // console.||(c)
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    checkMinMax(list){
      if (list.hasOwnProperty('billing') && !isNaN(parseInt(list.billing.price)) && parseInt(list.billing.price)<this.min){
        this.min=Math.floor(parseInt(list.billing.price)/500) * 500
      } else if (list.hasOwnProperty('billing') && !isNaN(parseInt(list.billing.price))&& parseInt(list.billing.price)>this.max) {
        this.max= Math.ceil(parseInt(list.billing.price)/500)*500
      }

    },

    setDisplayed(payload){
      // SearchBarRooms('Set displayed', payload);
      console.log(payload);
      this.displayed=payload.current
      this.numberOfPages=Math.ceil((payload.other.length + payload.current.length)/50)
      this.others=payload.other
      let t=[]
      // console.log(this.);
      for (var i = 0; i < this.displayed.length; i++) {
        t.push(this.displayed[i]['.key'])
      }
      t=JSON.stringify(t)
      dataLayer.push({
        'event':'updateFilter',
        ids:t
      })
    },
    matchRoomsApts(){
      if (this.roomsLoaded && this.apartmentsLoaded){
        for (var i = 0; i < this.rooms.length; i++) {
          this.rooms[i].url="/details/"+this.rooms[i]['.key']
          if (this.apartments[this.rooms[i].apartmentId]!=undefined) {
            this.rooms[i].general.location=this.apartments[this.rooms[i].apartmentId].general.location
            this.allRooms.push(this.rooms[i])
          }
        }
      }
    },
    setApartments(){
      this.filtered=JSON.parse(JSON.stringify(this.allApts))
      // console.(this.filtered);
      // console.log(this.allApts);
      //filter apts
    },
    setFilter(to){
      console.log('SET FILTER APT', to);
      dataLayer.push({
        searchString:to.fullPath
      })
      if (to.query.name!=undefined){
        this.filters.locName=to.query.name
        this.zoom=14
        this.filters.orderBy = 'distance'
      }
      if (to.query.lat!=undefined && isNaN(parseFloat(to.query.lat))!=true ){
        this.center.lat=parseFloat(to.query.lat)
        this.filters.lat=to.query.lat
        this.setArea(this.center)
      }
      if (to.query.lng!=undefined && isNaN(parseFloat(to.query.lng))!=true ){
        this.center.lng=parseFloat(to.query.lng)
        this.filters.lng=to.query.lng
        this.setArea(this.center)
      }
      if (moment(to.query.mid).isValid()){
        this.filters.mid = to.query.mid
      } else {
        this.filters.mid=""
      }
      if (moment(to.query.mod).isValid()){
        this.filters.mod = to.query.mod
      } else {
        this.filters.mod=""
      }
      // console.log('The price:', parseFloat(to.query.price));
      if (!isNaN(parseFloat(to.query.price))){
        this.filters.price = parseFloat(to.query.price)
      } else {
        this.filters.price = undefined
      }
      // SearchBarRooms(this.filters.price, 'the filter.price');
      if (!isNaN(parseInt(to.query.nor))){
        this.filters.nor = parseInt(to.query.nor)
      } else {
        this.filters.nor = undefined
      }
      if (to.query.fts!=undefined){
        this.filters.fts=JSON.parse(to.query.fts)
      }
      this.filters.bedType = ""
      this.filters.propType = ""
      this.filters.orderBy = to.query.orderBy
      this.filters.cLength= to.query.cLength
      this.filters.aptType = to.query.aptType

      this.setCookie('filters', this.filters, 30)
      // console.log('filter');
      if (this.loading==false){
        // console.log('SET FILTER FILTER');
        this.filterLists()
      }
    },
    filterLists(){
      // console.log('do filterList');
      this.filtered=JSON.parse(JSON.stringify(this.allApts))
      // console.log('FILTER');
      if (this.filters.mid!=="" && this.filters.mid!=undefined && moment(this.filters.mid).isValid()){
        // console.log('filter MId');
        this.filtered=this.filtered.filter(list => this.filterMid(list))
      }
      if (this.filters.nor!="" && this.filters.nor!=undefined && this.filters.mid!="" && this.filters.mid!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterNorMid(list))
        this.numberAvNow=this.filtered.length
      }
      if (this.filters.price!="" && this.filters.price!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterPrice(list))
      }
      if (this.filters.aptType!="" && this.filters.aptType!=undefined && this.filters.aptType!='any'){
        // console.log('do filterAptType');
        this.filtered=this.filtered.filter(list=> this.filterAptType(list))
      }
      if (this.filters.nor!="" && this.filters.nor!=undefined ){
        this.filtered=this.filtered.filter(list=> this.filterNor(list))
      }
      // console.log(this.filtered.length);
      if (this.filters.cLength!="" && this.filters.cLength!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterCLength(list))
      }
      if (this.filters.fts!="" && this.filters.fts!=undefined){
        this.filtered=this.filtered.filter(list=> this.filterFts(list))
      }
      this.orderBy(this.filters.orderBy)
    },
    filterFts(list){
      let t=true
      let rules=['noPets', 'noSmoking']
      for (var i = 0; i < this.filters.fts.length; i++) {
        if (rules.includes(this.filters.fts[i])){
          if (list.hasOwnProperty('contractDetails') && list.contractDetails.hasOwnProperty('rules') && list.contractDetails.rules[this.filters.fts[i]].value==false){
            continue
          } else {
            return false
          }
        } else if (this.filters.fts[i]=='girlsOnly'){
          if (list.hasOwnProperty('contractDetails') && list.contractDetails.hasOwnProperty('rules') && list.contractDetails.rules[this.filters.fts[i]].value==true){
            continue
          } else {
            return false
          }
        } else if (this.filters.fts[i]=='kitchen'){
          if (list.hasOwnProperty('featureList') && list.featureList.hasOwnProperty('kitchenType') && list.featureList.kitchenType!='None'){
            continue
          } else {
            return false
          }

        } else if (this.filters.fts[i]=='utilitiesIncluded'){
          if (list.type=="wholeApt" && list.hasOwnProperty('billing') && list.billing.hasOwnProperty('utilities') && list.billing.utilities=='Included in rent'){
            continue
          } else if (list.type=="roomApt" && list.hasOwnProperty('contractDetails') && list.contractDetails.hasOwnProperty('utilities') && list.contractDetails.utilities=='Included in rent' )  {
            continue
          } else {
            return false
          }
        } else if (list.featureList.hasOwnProperty('features') && list.featureList.features.includes(this.filters.fts[i]) ){
          continue
        } else {
          return false
        }
      }
      return t
    },
    filterMid(list){
      // console.log(list.general.nextAvailability);
      if (list!=undefined && list.general.nextAvailability!=undefined && moment(list.general.nextAvailability).isValid()){
        let lowLimit = moment(this.filters.mid).subtract(1, 'M').subtract(15, 'd')
        let topLimit = moment(this.filters.mid).add(1, 'M')
        let val=moment(list.general.nextAvailability)
        // console.log(lowLimit.format('LL'), topLimit.format('LL'), val.format('LL'));
        if (val.isBetween(lowLimit,topLimit, null, '[]')){
          return true
        } else {
          // console.log('false, coz not included');
          if (list.acceptSummer==true) {
            // console.log('true', list);
            // console.log('moment(this.filters.mid).isBetween(moment(July 31),moment(November 1))',moment().month("July").date('31'),);
              if (moment(this.filters.mid).isBetween(moment().month("July").date('31'),moment().month("November").date('1'))  && moment(list.general.nextAvailability).isBefore(moment().month("September").date('1') )){
                return true
              } else if (moment(this.filters.mid).isBetween(moment().month("May").date('31'),moment().month("August").date('1')) && moment(list.general.nextAvailability).isAfter(moment().month("August").date('1')) && list.septBooked==true && list.summerBooked==false ) {
                return true
              } else {
                // console.log( 'false,', moment(list.general.nextAvailability).format('L'));
                return false
              }
          } else {
            // console.log( 'false, ',list.acceptSummer, moment(list.general.nextAvailability).format('L'));
            return false
          }
        }
      } else {
        // console.log('ffalse coz', list, moment(list.general.nextAvailability).isValid());
        return true
      }
    },
    filterPrice(list){
      // console.SearchBarRooms('item:' , list, list.billing.price, this.filters.price);
      // console.log(list);
      if (list!=undefined && list.billing.price<=this.filters.price){
        return true
      } else {
        return false
      }
    },
    filterNor(list){
      // console.SearchBarRooms('item:' , list, list.billing.price, this.filters.price);
      if (list!=undefined && list.featureList!=undefined && list.featureList.numberOfBedrooms>=this.filters.nor){
        return true
      } else if (list!=undefined && list.featureList==undefined){
        console.error(list);
      } else {
        return false
      }
    },
    filterAptType(list){
      // console.log('do apttype dfilter');
      if (list!=undefined && list.type==this.filters.aptType){
        return true
      } else {
        return false
      }
    },
    filterCLength(list){
      if(list!=undefined){
        if (list.cValue==undefined){
          list.cValue=this.setContractValue(list)
        }
        if (list.cValue<=this.filters.cLength){
          return true
        } else {
          return false
        }
      }
    },
    filterNorMid(list){
      if (list!=undefined){
        // lis/t.
        // console.log(list.type);
        if (list.type!='wholeApt'){
          // console.log('checkAv');
          let noa=this.checkAvAtMid(list)
          if (noa>=this.filters.nor){
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      }
    },
    checkAvAtMid(list){
      // console.log(list);
      let tRooms=this.rooms
      list.cRooms= tRooms.filter(room => this.getRooms(room,list))
      // console.log('the rooms:', list.cRooms)
      list.cRooms=list.cRooms.filter(room => this.filterMid(room))
      // console.log('number of available rooms', list.cRooms.length);
      return list.cRooms.length
      console.log();
    },
    getRooms(room,list){
      // console.log(room.apartmentId,list['.key'])
      if (room.apartmentId==list['.key']){
        return true
      } else {
        return false
      }
    },

    setContractValue(listing){
      let contract=""
      if (listing.type=="room"){
        contract= this.apartments[listing.apartmentId].contractDetails.contractLength
      } else {
        contract= listing.contractDetails.contractLength
      }
      let cValue=0
      switch (contract) {
        case '1 month':
          cValue=1
          break;
        case '3 months':
          cValue=2
          break;
        case '5 months':
          cValue=3
          break;
        case '12 months':
          cValue=4
          break;
        case 'Open contract':
          cValue=5
          break;
        default:
          cValue=0
      }
      return cValue
    },
    orderBy(order){
      switch (order) {
        case 'MRA':
            this.filtered.sort((a,b) =>this.sortMRA(a,b))
            this.ordered=false
          break;
        case 'priceLtH':
            this.filtered.sort((a,b) =>this.sortPLtH(a,b))
            this.ordered=true
          break;
        case 'priceHtL':
            this.filtered.sort((a,b) =>this.sortPHtL(a,b))
            this.ordered=true
          break;

        case 'distance':
            this.filtered.sort((a,b) =>this.sortDistance(a,b))
            this.ordered=true
          break;
        case 'closestToMoveIn':
            this.filtered.sort((a,b) =>this.sortMid(a,b))
            this.ordered=true
          break;
        case 'newest':
            this.filtered.sort((a,b) =>this.sortNew(a,b))
            this.ordered=true
          break;
        case 'oldest':
            this.filtered.sort((a,b) =>this.sortOld(a,b))
            this.ordered=true
          break;

        default:
          this.filtered.sort((a,b) =>this.sortMRA(a,b))
          this.ordered=false
      }
    },
    sortMRA(a,b){
      if (a.general.score==undefined){
        // console.log('No score a', a);
        a.general.score=0
      }
      if (b.general.score==undefined){
        // console.log('No score b', b);
        b.general.score=0
      }
      if (typeof(a.general.score)=='string'){
        // console.log('Set int a',a);
          a.general.score=parseInt(a.general.score)
      }
      if (typeof(b.general.score)=='string'){
        // console.log('Set int b',b);
          b.general.score=parseInt(b.general.score)
      }
      if(a.general.score>b.general.score){
        return -1
      }
      if(a.general.score<b.general.score){
        return 1
      }
      return 0

    },
    sortPHtL(a,b){
      if (typeof(a.billing.price)=='string'){
        // console.log('set a int');
          a.billing.price=parseInt(a.billing.price)
      }
      if (typeof(b.billing.price)=='string'){
        // console.log('set b int');
          b.billing.price=parseInt(b.billing.price)
      }
      if(a.billing.price>b.billing.price){
        return -1
      }
      if (a.billing.price<b.billing.price){
        return 1
      }
      return 0

    },
    sortPLtH(a,b){
      if (a.billing.price==undefined){
        a.billing.price=0
        // console.log('ERROR HERE ON A', a);
      }
      if (b.billing.price==undefined){
        b.billing.price=0
        // console.log('error on b',b)
      }
      // console.log(a.billing.price, parseInt(a.billing.price), isNaN(a.billing.price));
      if (typeof(a.billing.price)=='string'){
          a.billing.price=parseInt(a.billing.price)
          // console.log('set a int',a);
      }
      if (typeof(b.billing.price)=='string'){
        // console.log('set b int');
          b.billing.price=parseInt(b.billing.price)
      }
      if(a.billing.price<b.billing.price){
        // console.log('a>b',a.billing.price, b.billing.price);
        return -1
      }
      if (a.billing.price>b.billing.price){
        return 1
      }
      return 0

    },
    sortDistance(a,b){
      if (a.distance==undefined){
        a.distance=999999
      }
      if (b.distance==undefined){
        b.distance=999999
      }
      if (typeof(a.distance)=='string'){
        a.distance=parseInt(a.distance)
      }
      if (typeof(b.distance)=='string'){
        b.distance=parseInt(b.distance)
      }
      if (a.distance<b.distance){
        return -1
      }
      if (a.distance>b.distance){
        return 1
      }
      return 0
    },
    sortMid(a,b){
      // console.log(Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability))),Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability))));
      if (Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability)))>Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability)))) {
          return 1
      }
      if (Math.abs(moment(this.filters.mid).diff(moment(a.general.nextAvailability)))<Math.abs(moment(this.filters.mid).diff(moment(b.general.nextAvailability)))) {
          return -1
      }
      return 0
    },
    sortNew(a,b){
      if (moment(a.lastModified).valueOf()>moment(b.lastModified).valueOf()){
        return -1
      }
      if (moment(a.lastModified).valueOf()<moment(b.lastModified).valueOf()){
        return 1
      }
      return 0
    },
    sortOld(a,b){
      if (moment(a.lastModified).valueOf()>moment(b.lastModified).valueOf()){
        return 1
      }
      if (moment(a.lastModified).valueOf()<moment(b.lastModified).valueOf()){
        return -1
      }
      return 0
    },
    setFX(curr){
      // console.log('setFX');
      if (curr!="TWD" && curr!="" && curr!=undefined){
        let fxLink = 'TWD_'+curr

      // console.log('this.user currency: ', this.user.currency)
        axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=' + fxLink + '&compact=ultra&apiKey=2d6cf65af2f8557d2378')
        .then(res => {
          // console.log(res.data[fxLink]);
          this.fx=res.data[fxLink]
        })
        .catch(err => {
          this.fx=0
          console.log(err, 'cant get conversion rate')
        })
      } else {
        this.fx=1
      }


    },


  },
  filters: {
  },
  watch: {
    '$route'(to, from) {//watch the route for any changes from the bar.
      // SearchBarRooms('do watch route', to.query);
      // console.log('ROUTE WATCHER');
      if (to.query.lat && to.query.lng) {//set the location correctly
        this.center.lat = parseFloat(to.query.lat)
        this.center.lng = parseFloat(to.query.lng)
      }
      this.setFilter(to)
      // console.||('execute the watch function!!!')

      if (this.user!=undefined && this.user.id!=undefined && this.user.id!=null){
        axios.patch('users/'+this.user.id+'/query.json?auth='+this.$store.state.auth.idToken,to.query)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    urgencyVal(){
      if (this.filters.mid!="" && this.filters.mid!=undefined && this.filters.nor!="" && this.filters.nor!=undefined && moment(this.filters.mid).isValid()){
        if (moment().add(6,"months").isBefore(moment(this.filters.mid))){
          return 3
        } else if (moment().add(4,"months").isBefore(moment(this.filters.mid))){
          return 2
        } else {
          return 1
        }
      } else {
        return 0
      }
    },
    percAv(){
      if (this.numberAvNow!=0 && this.totalAv!=0){
        return Math.round(this.numberAvNow/this.totalAv*100)
      }
      else return 1
    },
    totalAv(){
      return this.allApts.length
    },
    currency(){
      // console.log('Set currency');
      if (this.user.currency!=undefined && this.user.currency != "") {
        this.setFX(this.user.currency)
        return this.user.currency
      } else {
        this.setFX("EUR")
        return "EUR"
      }
    },

    loading(){
      // console.log('Do Loading');
      if (this.roomsLoaded==true && this.wholeAptsLoaded==true && this.apartmentsLoaded==true){
        // console.log('Finally Loaded');
          this.setApartments()
          this.filterLists()
          return false
      } else {
          return true
      }
    },
  },
  created() {
    this.checkQuery()
    this.filtered=this.allApts
    this.setFilter(this.$route)
      // this.filters=this.$route.query
  },
  metaInfo() {
    let meta=[
      // console.log('our this: ', this.initialLoad)
      { vmid:'identifier-url', name:'identifier-url',content:'https://myroomabroad.com'+this.$route.path},
      { vmid:'description', name:'description', content:this.initialLoad ? config.TAGLINE : this.areas[this.area].desc},
      {vmid:'og:url', property:'og:url' ,content:'https://myroomabroad.com'+this.$route.path},
      {vmid:'og:title', property:'og:title',content:this.initialLoad ? config.TITLE : 'My Room Abroad - Apartments near '+this.$route.query.name},
      {vmid:'og:description',property:'og:description',content:this.initialLoad ? config.TAGLINE : this.areas[this.area].desc},
      {vmid:'twitter:title', name:'twitter:title', content:this.initialLoad ? config.TITLE : 'My Room Abroad - Apartments near '+this.$route.query.name},
      {vmid:'twitter:url', name:'twitter:url', content:'https://myroomabroad.com'+this.$route.path},
      {vmid:'twitter:description', name:'twitter:description', content:this.initialLoad ? config.TAGLINE : this.areas[this.area].desc},

      // { vmid: 'description', name: 'description', content: 'loading' },
    ]
    return {
      title:this.initialLoad ? config.TITLE : 'My Room Abroad - Apartments near '+this.$route.query.name, // set a title
      titleTemplate: '%s - My Room Abroad', // title is now "My Example App - Yay!"
      meta:meta
    }
  },

  components:{
    SearchBarRooms,
    AptCard,
    Loading,
    Area,
    OpenMaps,
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
.results-col {
    padding-top: 10px;
    overflow-y: scroll;
    height: 80vh;
}

</style>
